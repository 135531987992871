import _constants2 from "./constants";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.cleanSVGProps = void 0;
var _constants = _constants2;
exports.HTMLProps = _constants.HTMLProps;
exports.RDFProps = _constants.RDFProps;
exports.SVGProps = _constants.SVGProps;
exports.ValidEvents = _constants.ValidEvents;
var DataRegex = /data-([a-zA-Z0-9\-]*)/;
var AriaRegex = /aria-([a-zA-Z0-9\-]*)/;
/**
 * Return an object containing the valid props to apply to an HTMLElement.
 *
 * @param {Object} props - Props to be filtered and applied to DOM element.
 * @param {Array} excludes - Array of props to exlude
 * @param {Array} customAttributes - Array of custom attributes to include
 * @return {Object}
 */

var cleanProps = function cleanProps(props, excludes, customAttributes) {
  if (props === void 0) {
    props = {};
  }

  if (excludes === void 0) {
    excludes = [];
  }

  if (customAttributes === void 0) {
    customAttributes = [];
  }

  var returnProps = Object.assign({}, props);
  var validProps = Object.keys(returnProps).filter(function (key) {
    if (excludes.indexOf(key) !== -1) {
      return false;
    }

    if (customAttributes.indexOf(key) > -1) {
      return true;
    }

    if (_constants.HTMLProps.indexOf(key) !== -1) {
      return true;
    }

    if (_constants.RDFProps.indexOf(key) !== -1) {
      return true;
    }

    if (DataRegex.test(key)) {
      return true;
    }

    if (AriaRegex.test(key)) {
      return true;
    }

    if (_constants.ValidEvents.indexOf(key) !== -1) {
      return true;
    }

    return false;
  });
  Object.keys(returnProps).forEach(function (key) {
    if (validProps.indexOf(key) === -1) {
      delete returnProps[key];
    }
  });
  return returnProps;
};
/**
 * Return an object containing the valid props to apply to an SVGElement.
 *
 * @param {Object} props
 * @param {Object}
 */


var cleanSVGProps = function cleanSVGProps(props, excludes) {
  if (props === void 0) {
    props = {};
  }

  if (excludes === void 0) {
    excludes = [];
  }

  var returnProps = Object.assign({}, props);
  var validProps = Object.keys(returnProps).filter(function (key) {
    if (excludes.indexOf(key) !== -1) {
      return false;
    }

    if (_constants.SVGProps.indexOf(key) !== -1) {
      return true;
    }

    return false;
  });
  Object.keys(returnProps).forEach(function (key) {
    if (validProps.indexOf(key) === -1) {
      delete returnProps[key];
    }
  });
  return returnProps;
}; // Exports ___________________________________________________________________


exports.cleanSVGProps = cleanSVGProps;
var _default = cleanProps;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule,
      HTMLProps = exports.HTMLProps,
      RDFProps = exports.RDFProps,
      SVGProps = exports.SVGProps,
      ValidEvents = exports.ValidEvents;
const _cleanSVGProps = exports.cleanSVGProps;
export { _cleanSVGProps as cleanSVGProps };